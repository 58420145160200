@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Neucha&display=swap');
/* color palette 
  light background: #AFC2D5
  dark background: #0A1128
  neutral: #716969
  text: #DFEFCA
  highlight: #FFF9A5
*/
* {
  font-family: 'League Spartan', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

p {
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0rem 2rem;
  overflow-wrap: break-word;
  hyphens: auto;
  color: #050401;
}

body {
  background-color: #AFC2D5;
}

.icon {
  font-size: 1.75rem;
  color: #DFEFCA;
}

.navbar {
  background: #0a1128;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  
  z-index: 10;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

.column-container {
  padding: 1rem calc((100vw - 1000px) / 2);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-width: 800px) {
  .row-container {
    display: flex;
    flex-direction: column;
  }

  img {
    width: 600px;
    max-width: 100%;
    object-fit: contain;
    padding: .5rem;
    align-self: center;
    
    margin: auto;
    display: block;
  }

  p {
    margin: .5rem;
  }

  .text-content {
    padding: .5rem .5rem;
  }

  .video-responsive iframe {
    padding: 1rem;
  }
  
}

@media only screen and (min-width: 800px) {
  img {
    max-width: 400px;
    object-fit: contain;
    padding: 0;
    align-self: center;
  }
}

h1 {
  text-align: center;
  color: #050401;
}

h2 {
  text-align: center;
  color:#14224e;
}

h3 {
  margin: 0rem 2rem;
}

a {
  text-decoration: none;
  color: #345cdf;
}

.text-content {
  display: block;
  align-items: center;
  text-decoration: none;
  padding: .5rem 1rem;
}

.logo {
  font-family: 'Neucha', cursive;
  font-size: 1.6rem;
  color: #DFEFCA;
}


.caption {
  color: #334658;
  text-align: center;
  font-weight: 300;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}



/* .content {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 1rem 1rem;
} */
